import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Loader from '../components/Loader';
import PrivateRoute from '../features/Auth/components/PrivateRoute';

const Login = React.lazy(() => import('./Login'));
const Home = React.lazy(() => import('./Home'));
const NotFound = React.lazy(() => import('./NotFound'));

const Router = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (inProgress !== InteractionStatus.None) {
    return <Loader mt="40vh" />;
  }

  return (
    <React.Suspense fallback={<Loader mt="40vh" />}>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route
          path="/"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Suspense>
  );
};

export default Router;
