import { CSSProperties } from 'styled-components';
import { createPropsToStylesParser } from '../core';
import { PropsConfig } from '../types';

export type FlexboxProps = {
  alignItems?: CSSProperties['alignItems'] | Nullable<CSSProperties['alignItems']>[];
  alignContent?: CSSProperties['alignContent'] | Nullable<CSSProperties['alignContent']>[];
  justifyContent?: CSSProperties['justifyContent'] | Nullable<CSSProperties['justifyContent']>[];
  gap?: CSSProperties['gap'] | Nullable<CSSProperties['gap']>[];
  flexWrap?: CSSProperties['flexWrap'] | Nullable<CSSProperties['flexWrap']>[];
  flexBasis?: CSSProperties['flexBasis'] | Nullable<CSSProperties['flexBasis']>[];
  flexDirection?: CSSProperties['flexDirection'] | Nullable<CSSProperties['flexDirection']>[];
  flexGrow?: CSSProperties['flexGrow'] | Nullable<CSSProperties['flexGrow']>[];
  flexShrink?: CSSProperties['flexShrink'] | Nullable<CSSProperties['flexShrink']>[];
  flex?: CSSProperties['flex'] | Nullable<CSSProperties['flex']>[];
  justifySelf?: CSSProperties['justifySelf'] | Nullable<CSSProperties['justifySelf']>[];
  alignSelf?: CSSProperties['alignSelf'] | Nullable<CSSProperties['alignSelf']>[];
  order?: CSSProperties['order'] | Nullable<CSSProperties['order']>[];
};

const getPropsConfigMap = (): PropsConfig<FlexboxProps> => ({
  alignItems: {
    properties: ['alignItems'],
  },
  alignContent: {
    properties: ['alignContent'],
  },
  justifyContent: {
    properties: ['justifyContent'],
  },
  flexWrap: {
    properties: ['flexWrap'],
  },
  flexBasis: {
    properties: ['flexBasis'],
  },
  flexDirection: {
    properties: ['flexDirection'],
  },
  flexGrow: {
    properties: ['flexGrow'],
  },
  flexShrink: {
    properties: ['flexShrink'],
  },
  flex: {
    properties: ['flex'],
  },
  gap: {
    properties: ['gap'],
  },
  justifySelf: {
    properties: ['justifySelf'],
  },
  alignSelf: {
    properties: ['alignSelf'],
  },
  order: {
    properties: ['order'],
  },
});

export const flexbox = createPropsToStylesParser<FlexboxProps>(getPropsConfigMap);
