import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset};

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${({ theme }) => theme.color.bg.primary};
    color: ${({ theme }) => theme.color.fg.primary};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSizes.body1};
    line-height: ${({ theme }) => theme.lineHeight};
    margin: 0;
  }

  a img {
    border: 0;
  }

  strong {
    font-weight: 500;
  }

  button,
  input[type="submit"] {
    background-color: transparent;
    color: currentColor;
    display: inline-block;
    border: 0;
    border-radius: 0;
    font-family: ${({ theme }) => theme.fontFamily};
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    cursor: pointer;
  }

  p + p {
    margin-top: 24px;
  }
`;
