import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import PolicyContext from './context';
import { IPolicy, IPolicyContext } from './types';
import { msalWrapperInstance } from '../Auth';

interface IProps {
  children: ReactNode;
}

export const PolicyProvider = ({ children }: IProps) => {
  const { accounts } = useMsal();
  const [policy, setPolicy] = useState<IPolicy>();

  const fetchPolicy = useCallback(async () => {
    if (accounts.length > 0) {
      const token = await msalWrapperInstance.getAccessToken();
      const response = await fetch(
        `/api/policy-service/v1/user/cdsid/${accounts[0].username.split('@')[0]}`,
        {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        }
      );

      const body = await response.json();
      setPolicy({
        userId: body.id,
        name: `${body.first_name} ${body.last_name}`,
        organizationIds: body.organization_ids,
      });
    } else {
      setPolicy(undefined);
    }
  }, [accounts]);

  useEffect(() => {
    fetchPolicy();
  }, [accounts]);

  const contextValue: IPolicyContext = {
    policy,
    loading: false,
  };

  return <PolicyContext.Provider value={contextValue}>{children}</PolicyContext.Provider>;
};

export const usePolicyContext = () => {
  const context = useContext(PolicyContext);
  if (context === undefined) {
    throw new Error('usePolicyContext can only be used inside PolicyProvider');
  }
  return context;
};
