import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';

import '../../assets/css/fonts.css';
import { GlobalStyle } from '../../components/GlobalStyle';
import { theme } from '../../style/theme';

import Routes from '../../routes';
import { PortalElement, PortalNamespace } from '../../components/Portal';
import { msalWrapperInstance } from '../../features/Auth';
import { GraphQLClientProvider } from '../GraphQLClient';
import { PolicyProvider } from '../PolicyProvider';

const App = () => {
  return (
    <MsalProvider instance={msalWrapperInstance.msal}>
      <PolicyProvider>
        <BrowserRouter basename="/">
          <GraphQLClientProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Routes />
              <PortalElement id={PortalNamespace.DefaultPortal} />
            </ThemeProvider>
          </GraphQLClientProvider>
        </BrowserRouter>
      </PolicyProvider>
    </MsalProvider>
  );
};

export default App;
