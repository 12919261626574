declare const APP_AUTHORITY: string;
declare const APP_CLIENT_ID: string;
declare const APP_REDIRECT_URI: string;
declare const APP_POST_LOGOUT_URI: string;
declare const GRAPHQL_URL: string;
declare const SENTRY_DSN: string;
declare const ENVIRONMENT: string;

export const config: IConfig = {
  APP_AUTHORITY,
  APP_CLIENT_ID,
  APP_REDIRECT_URI,
  APP_POST_LOGOUT_URI,
  GRAPHQL_URL,
  SENTRY_DSN,
  ENVIRONMENT,
};
